import React from "react"
import * as Sentry from '@sentry/browser';
Sentry.init({dsn: "https://2764d477abbc48e0bfb198c193957891@sentry.io/1798081"});
import PropTypes from "prop-types"

import ConsignmentGuide from './ConsignmentGuide'
import ConsignmentForm from './ConsignmentForm'

class Consignment extends React.Component {
  componentDidMount() {
    // console.log(<%= asset_path "selling-form-pink.png" %>);
    console.log('Consignment props: ', this.props);
  }

  render () {
    
    return (
      <div className="consignment-new">
        <ConsignmentGuide guide_images={this.props.guide_images}/>
        <ConsignmentForm {...this.props} />
      </div>
    );
  }
}

export default Consignment