import React from "react"
import PropTypes from "prop-types"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ConsignmentTnc(props) {
    return (
		<Modal show={props.show} onHide={() => { props.onHide() }} animation={false} centered>
        <Modal.Body>
		<div className="row">
		      	<div className="col-md-10 col-md-offset-1">
		      		<div className="panel-group product_accordion faq-accordion" id="accordion-sell">
							<div className="panel panel-default">
								<div className="panel-heading">
									<a data-toggle="collapse" data-parent="#accordion-sell" href="#collapse1-sell" className="collapse-icon">
										<h4 className="panel-title">
											Cara Titip Jual di Le-portier
											<span className="text-right span-collapse-icon">
												<i className="fa fa-minus"></i>
											</span>
										</h4>
									</a>
								</div>
								<div id="collapse1-sell" className="panel-collapse collapse in">
									<div className="panel-body">
										<div className="row">
											<div className="col-xs-2 col-md-1 pr-0">
												<img src={props.guide_images.faq_sell_1} className="" alt="sell-1" width="30px" style={{float: 'right'}}/>
											</div>
											<div className="col-xs-10 col-md-11">
												<h5 className="lato-reg pr-1" style={{display: 'inline', lineHeight: '34px'}}>Isi form consign di website kami</h5>
												<ul>
													<li>Product yang di terima meliputi Tas, Sepatu dan Accessories dari brand berikut Link Brand</li>
													<li>Pastikan product yang akan di consign dalam kondisi baik dan masi bisa di gunakan (tidak berjamur, tidak mengalami kerusakan yg menyebabkan tidak nyaman saat di pakai oleh pembeli)</li>
													<li>Apabila form consign sudah masuk system Le-Portier, owner akan menerima email confirmasi melalui email dan Le-Portier akan menghubungi dalam waktu H+2 untuk penjadwalan pickup. (Jika belum menerima email confirmasi, berarti form consign belum masuk ke dalam system Le-Portier. Dimohon juga untuk di cek di spam maupun promotions di inbox email.)</li>
												</ul>
											</div>
										</div>

										<div className="row">
											<div className="col-xs-2 col-md-1 pr-0">
												<img src={props.guide_images.faq_sell_2} className="" alt="sell-1" width="30px" style={{float: 'right'}}/>
											</div>
											<div className="col-xs-10 col-md-11">
												<h5 className="lato-reg pr-1" style={{display: 'inline', lineHeight: '34px'}}>Barang akan di pick up</h5>
												<ul>
													<li>Untuk area Surabaya menggunakan private porter</li>
													<li>Untuk area di luar Surabaya, Le-Portier menggunakan kerjasama dengan pihak DHL</li>
													<li>Bila tidak berkenan, bisa juga melalui dropoff sendiri ke alamat Le-Portier</li>
													<li>Pastikan barang yang akan di pick up dalam kondisi terpacking aman dan rapi. Le-Portier tidak bertanggung jawab atas kerusakan yang terjadi selama barang berada dalam proses pengiriman.</li>
													<li><span className="lato-reg">FREE PICK UP</span> semua lokasi di Indonesia <span className="lato-reg">hanya berlaku</span> apabila product terjual melalui Le-portier. </li>
												</ul>
											</div>
										</div>

										<div className="row">
											<div className="col-xs-2 col-md-1 pr-0">
												<img src={props.guide_images.faq_sell_3} className="" alt="sell-1" width="30px" style={{float: 'right'}}/>
											</div>
											<div className="col-xs-10 col-md-11">
												<h5 className="lato-reg pr-1" style={{display: 'inline', lineHeight: '34px'}}>Barang diproses untuk di jual dan di promote di media promosi Le-Portier</h5>
												<ul>
													<li>Setelah barang diterima dan di authentikasi, Le-Portier akan mengirimkan email confirmasi berisi data lengkap barang beserta rekomendasi harga pasaran</li>
													<li>Barang dititipkan di Le-Portier secara exclusive minimum 3 bulan</li>
												</ul>
											</div>
										</div>

										<div className="row">
											<div className="col-xs-2 col-md-1 pr-0">
												<img src={props.guide_images.faq_sell_4} className="" alt="sell-1" width="30px" style={{float: 'right'}}/>
											</div>
											<div className="col-xs-10 col-md-11">
												<h5 className="lato-reg pr-1" style={{display: 'inline', lineHeight: '34px'}}>Barang terjual</h5>
												<ul>
													<li>Dana akan masuk pada rekening owner setelah product terjual dalam kurun waktu H+7 terhitung pada saat product di terima oleh buyer kami </li>
													<li>Transfer dana akan diproses sesuai dengan nomor rekening yang tercantum pada list form pada saat pengisian form consign di website Le-Portier (hanya menerima rekening BCA dan Mandiri)</li>
													<li>Apabila terdapat perubahan data rekening, mohon confirmasikan kembali melalui email ke hello@le-portier.com dengan subject (Revisi Nomor Account Bank)</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading">
									<a data-toggle="collapse" data-parent="#accordion-sell" href="#collapse2-sell" className="collapse-icon">
										<h4 className="panel-title">
											Bagaimana Cara Menentukan Harga Jual Product Anda
											<span className="text-right span-collapse-icon">
												<i className="fa fa-plus"></i>
											</span>
										</h4>
									</a>
								</div>
								<div id="collapse2-sell" className="panel-collapse collapse">
									<div className="panel-body">
										<p align="justify">Product yang anda titip jualkan pada kami berlaku sistem komisi sebagai berikut:</p>
										<img src={props.guide_images.commision_rate} className="img-responsive mb-1" alt="table-start-selling"/>
										<p align="justify">Price yang ada di website kami merupakan harga yang telah di sepakati antara Owner dan Le-portier. "Rekomendasi harga pasaran dari Le-Portier berdasarkan hal-hal berikut: kondisi pada saat product diterima, kelengkapan product mulai dari Box, Strap, Authentic Card, Tag, Booklet dll, ketersediaan product yang ada di pasaran (rare item or limited stock), permintaan pembeli pada website kami</p>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading">
									<a data-toggle="collapse" data-parent="#accordion-sell" href="#collapse3-sell" className="collapse-icon">
										<h4 className="panel-title">
											Berapa lama product tersimpan pada warehouse Le-Portier
											<span className="text-right span-collapse-icon">
												<i className="fa fa-plus"></i>
											</span>
										</h4>
									</a>
								</div>
								<div id="collapse3-sell" className="panel-collapse collapse">
									<div className="panel-body">
										<ul>
											<li>Product yang di consign harus dititipkan di Le-Portier minimum 3 bulan terhitung sejak produk aktif di website Le-Portier. Ini agar mempermudah buyer kami saat ingin membeli product yang di consign di Le-Portier</li>
											<li>Batas maximum penitipan product di Le-Portier selama 1 tahun terhitung sejak produk aktif di website Le-Portier</li>
											<li>Apabila pada batas waktu tersebut product belum terjual, link ke no. 4</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading">
									<a data-toggle="collapse" data-parent="#accordion-sell" href="#collapse4-sell" className="collapse-icon">
										<h4 className="panel-title">
											Apa yang akan di lakukan apabila item tidak berhasil terjual pada waktu yang telah di sepakati?
											<span className="text-right span-collapse-icon">
												<i className="fa fa-plus"></i>
											</span>
										</h4>
									</a>
								</div>
								<div id="collapse4-sell" className="panel-collapse collapse">
									<div className="panel-body">
										<ol className="list-waktu">
											<li><b>Lebih dari 3 bulan</b>
												<ul>
													<li>Owner bisa reprice minimum 5% atau mengajukan retur LINK Retur</li>
													<li>Biaya pick up awal dan biaya retur akan dibebankan ke Owner</li>
												</ul>
											</li>
											<li><b>Lebih dari 1 tahun</b>
												<ul>
													<li>Owner bisa reprice minimum 20% atau mengajukan retur LINK Retur</li>
													<li>Biaya pick up awal dan biaya retur akan dibebankan ke Owner</li>
												</ul>
											</li>
										</ol>
										<p align="justify">* Retur akan kami proses setelah kami menerima konfirmasi bukti transfer shipping charge melaui account BCA 4116 909090 atas nama Steven Eka Halim (bisa melalui line bussines, whatsapp, maupun email)</p>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>
		</Modal.Body>
        <Modal.Footer style={{textAlign: 'center'}}>
          <Button variant="default" onClick={() => { props.onHide() }}>
            Decline
          </Button>
          <Button variant="primary" onClick={() => { props.onNext() }}>
            Agree!
          </Button>
        </Modal.Footer>
      </Modal>
	)
};
