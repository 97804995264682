import React from "react"

export default function ConsignmentGuide(props) {
  return (
    <div className="container d-none d-md-block">
      <div className="row how-to-sell-container">
        
        <div className="col-xs-8 col-xs-offset-2">
          <div className="row text-center">
            <div className="col-xs-a text-center p-0 active">
              <img src={props.guide_images.selling_form_pink} className="img-responsive sell-steps" alt="Fill in the selling form"/>
              <p>1. Fill in the selling form</p>
            </div>
            <div className="col-xs-b text-center p-0">
              <img src={props.guide_images.arrow_black} className="img-responsive arrow" alt="arrow"/>
            </div>
            <div className="col-xs-a p-0">
              <img src={props.guide_images.wait_call_back} className="img-responsive sell-steps" alt="Fill in the selling form"/>
              <p>2. Wait for our call</p>
            </div>
            <div className="col-xs-b text-center p-0">
              <img src={props.guide_images.arrow_black} className="img-responsive arrow" alt="arrow"/>
            </div>
            <div className="col-xs-a p-0">
              <img src={props.guide_images.item_pickedup_black} className="img-responsive sell-steps" alt="Fill in the selling form"/>
              <p>3. The item is picked up</p>
            </div>
            <div className="col-xs-b text-center p-0">
              <img src={props.guide_images.arrow_black} className="img-responsive arrow" alt="arrow"/>
            </div>
            <div className="col-xs-a p-0">
              <img src={props.guide_images.item_posted_black} className="img-responsive sell-steps" alt="Fill in the selling form"/>
              <p>4. The item is posted</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
