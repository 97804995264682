import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import CurrencyInput from 'react-currency-input';
import _ from 'lodash';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import ConsignmentTnc from './ConsignmentTnc';

class ConsignmentForm extends React.Component {

  constructor(props) {
    super(props);

    let default_state_id = null;
    if(props.address && props.address.state_id) {
      const provinces = this.props.provinces;
      let selected_province = _.find(provinces, { 'id': props.address.state_id});
      if(selected_province) {
      default_state_id = {value: selected_province.id, label: selected_province.name};
      }
    } 

    this.state = { 
      products: [],
      val_category: null,
      val_brand: null,
      val_name: "",
      val_condition: 'new',
      val_includes: [],
      val_description: "",
      val_image: null,
      val_preview: null,
      val_price: '',
      val_price_value: 0,
      val_commission: 0,
      //
      val_firstname: props.address && props.address.firstname ? props.address.firstname : "",
      val_lastname: props.address && props.address.lastname ? props.address.lastname : "",
      val_phone: props.address && props.address.phone ? props.address.phone : "",
      val_whatsapp: "",
      val_line: "",
      val_address: props.address && props.address.address1 ? props.address.address1 : "",
      val_zipcode: props.address && props.address.zipcode ? props.address.zipcode : "",
      val_state_id: default_state_id,
      val_pickup_date: "",
      val_pickup_time: "09:00 - 12:00",
      
      val_bank: props.bank_account && props.bank_account.bank_name ? props.bank_account.bank_name.toLowerCase() : 'bca',
      val_account_name: props.bank_account ? props.bank_account.account_name : "",
      val_account_number: props.bank_account ? props.bank_account.account_number : "",
      
      val_referral_code: '',

      errors: new Map(),

      tnc_modal_open: false,
      tnc_agree: false,
      is_loading: false
    };

    this.inputOpenFileRef = React.createRef();
    this.submitBtnRef = React.createRef();
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.handleChangeStateId = this.handleChangeStateId.bind(this);
    this.handleChangeAccessories = this.handleChangeAccessories.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.showOpenFileDlg = this.showOpenFileDlg.bind(this);
    this.fileOnChangeHandler = this.fileOnChangeHandler.bind(this);
    this.handleAddMore = this.handleAddMore.bind(this);
    this.handleOpenTnC = this.handleOpenTnC.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  validateField(state_name, rules = [])
  {
    var state_value = this.state[state_name];
    for (let rule of rules) {
      if(rule == 'required' ) {
        if(state_value == null || state_value == "" || (Array.isArray(state_value) && state_value.length == 0)) {
          console.log('validateField [', state_name, '] rule: ', rule, ' >> not passed');
          return 'This field is required.';
        }
      } else if(rule == 'number') {
        if(/^\d+$/.test(state_value) == false) {
          return 'Please fill numbers only.';
        }
      }
    }

    return null;
  }

  async validateForm(final = false)
  {
    let rules = null;

    const product_rules = {
      val_category: 'required',
      val_brand: 'required',
      val_name: 'required',
      val_condition: 'required',
      val_image: 'required',
      val_price: 'required',
    }

    const {products} = this.state;

    if(final) {
      if(products.length > 0) {
        rules = {
          val_firstname: 'required',
          val_lastname: 'required',
          val_phone: 'required|number',
          val_address: 'required',
          val_zipcode: 'required|number',
          val_state_id: 'required',
          val_pickup_date: 'required',
          val_pickup_time: 'required',
          
          val_bank: 'required',
          val_account_name: 'required',
          val_account_number: 'required|number'
        }
      } else {
        rules = {
          ...product_rules,

          val_firstname: 'required',
          val_lastname: 'required',
          val_phone: 'required|number',
          val_address: 'required',
          val_zipcode: 'required|number',
          val_state_id: 'required',
          val_pickup_date: 'required',
          val_pickup_time: 'required',
          
          val_bank: 'required',
          val_account_name: 'required',
          val_account_number: 'required|number'
        }
      }
    } else {
      rules = {
        ...product_rules
      }
    }
    

    let errors = new Map();

    for (let rule of Object.entries(rules)) {
      var error = this.validateField(rule[0], rule[1].split('|'));
      console.log('error', error);
      error != null ? errors.set(rule[0], error) : null;
    };

    console.log('validateForm errors', errors);
    return await this.setStateAsync({errors: errors})
  }


  scrollTop() {
    scroll.scrollToTop();
  }

  componentWillMount() {
    this.loadAccessories();
  }

  loadCategories() {
    const { categories } = this.props;
    return categories.map((category) => {
      return {value: category.id, label: category.name};
    });
  }

  handleChangeCategory(selected) {
    console.log(selected);
    this.setState({val_category: selected});
  }

  loadBrands() {
    const { brands } = this.props;
    return brands.map((brand) => {
      return {value: brand.id, label: brand.name};
    });
  }

  handleChangeBrand(selected) {
    console.log(selected);
    this.setState({val_brand: selected});
  }

  loadProvinces() {
    const { provinces } = this.props;
    return provinces.map((province) => {
      return {value: province.id, label: province.name};
    });
  }

  handleChangeStateId(selected) {
    console.log(selected);
    this.setState({val_state_id: selected});
  }

  loadAccessories() {
    const {accessories} = this.props;
    let acc_data = accessories.map((acc) => {
      return { ...acc, checked: false };
    });
    this.setState({'val_includes': acc_data});
  }

  renderAccessories() {
    const {val_includes} = this.state;
    return val_includes.map((accessory) => {
      return (
        <FormControlLabel key={`accessory-${accessory.id}`}
          control={<Checkbox color="default" checked={ accessory.checked } 
          onChange={this.handleChangeAccessories} value={accessory.id} />}
          label={<Typography style={{ 'fontSize': '12px' }}>{accessory.name}</Typography>}
        />
      )
    })
  }

  handleChangeAccessories(e) {
    const {val_includes} = this.state;
    const item = e.target.value;
    const isChecked = e.target.checked;
    
    let index = _.findIndex(val_includes, ['id', parseInt(item)]);
    val_includes[index].checked = !val_includes[index].checked;
    this.setState({ val_includes: val_includes });
  }

  handleChangePrice(event, maskedvalue, floatvalue) {
    this.setState({val_price: maskedvalue, val_price_value: floatvalue});
  }

  moneyFormat(x, mask = false) {
    if(isNaN(x)) return '-';
    var formated_money = parseFloat(x).toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    if(mask) {
        var first_digit = formated_money.charAt(0);
        formated_money = first_digit + _.replace(formated_money.substring(1), /()[\d]/ig, '*');
    }
    return "IDR " + formated_money;
  }

  renderYouGet() {
    const price = parseFloat(this.state.val_price_value);

    if(price == null || price == 0.00) {
      return (
        <div></div>
      )
    }
    let priceAfterFee = 0;
    let description = '';
    let flat_fee = 0;
    let fee = 0;
    
    if(price <= 4999999) 
			flat_fee = 750000;
		else if(price <= 24999999) 
			fee = 16;
		else if(price <= 74999999)
			fee = 12;
		else 
			fee = 8;

    var descprice
    
		if(fee > 0)
			descprice = `${this.moneyFormat(price)} Selling Price - ${fee}% Le-Portier Fee`;
		else
			descprice = `${this.moneyFormat(price)} Selling Price - ${this.moneyFormat(flat_fee)} Le-Portier Fee`;
		
    var priceafterfee = price - (price * fee / 100) - flat_fee;
    
    if(priceafterfee < 0.00) {
      priceafterfee = 0;
    }

    return (
      <div className="price_detail">         
        <p className="first_p">$</p>
        <p className="second_p">YOU'LL GET</p>
        <p className="third_p" id="price-after-fee">{ this.moneyFormat(priceafterfee) }</p>
        <p className="last_p" id="desc-price">{ descprice }</p>
      </div>
    )
  }
  
  showOpenFileDlg(e) {
    e.preventDefault();
    this.inputOpenFileRef.current.click();
  }

  fileOnChangeHandler(event) {
    console.log(event.target.files[0])
  }

  fileOnChangeHandler(e) {
    let { val_image, val_preview } = this.state;
    let current_file = e.target.files.item(0);
    let file_url = URL.createObjectURL(current_file);
    val_image = current_file;
    val_preview = file_url;
    this.setState({
      val_image, val_preview
    });
    
  }

  async handleAddMore(e) {

    await this.validateForm();

    if(this.state.errors.size > 0) {
      ToastsStore.error("Please fill all required fields.");
      return;
    }

    console.log('after validateForm errors: ', this.state.errors);

    // let {products, val_category, val_brand, val_name, val_condition, val_includes,
    //   val_description, val_image, val_preview, val_price, val_price_value} = this.state;

    // let includes = [];
    // val_includes.forEach((value) => { if(value.checked) { includes.push(value.id)} });
    
    // products.push({
    //   category: val_category.value,
    //   brand: val_brand.value,
    //   name: val_name,
    //   condition: val_condition,
    //   includes: includes,
    //   description: val_description,
    //   image: val_image,
    //   image_preview: val_preview,
    //   price: val_price_value
    // });

    // this.setState({
    //   products: products,
    //   val_category: null,
    //   val_brand: null,
    //   val_name: "",
    //   val_condition: 'new',
    //   val_description: "",
    //   val_image: null,
    //   val_preview: null,
    //   val_price: '',
    //   val_price_value: 0.00,
    //   val_commission: 0.00
    // }, () => {
    //   this.loadAccessories();
    //   this.scrollTop();
    // });

    await this.pushToProducts();
    this.loadAccessories();
    this.scrollTop();
    ToastsStore.info("Product added!");
  }

  async pushToProducts() {
    return new Promise((resolve) => {
      let {products, val_category, val_brand, val_name, val_condition, val_includes,
        val_description, val_image, val_preview, val_price, val_price_value} = this.state;
  
      let includes = [];
      val_includes.forEach((value) => { if(value.checked) { includes.push(value.id)} });
      
      products.push({
        category: val_category.value,
        brand: val_brand.value,
        name: val_name,
        condition: val_condition,
        includes: includes,
        description: val_description,
        image: val_image,
        image_preview: val_preview,
        price: val_price_value
      });
  
      this.setState({
        products: products,
        val_category: null,
        val_brand: null,
        val_name: "",
        val_condition: 'new',
        val_description: "",
        val_image: null,
        val_preview: null,
        val_price: '',
        val_price_value: 0.00,
        val_commission: 0.00
      }, resolve);
    });

    
  }

  handleRemove(id) {
    let products = this.state.products;
    products.splice(id, 1);
    console.log('handleRemove id: ', id, ' products: ', products);
    this.setState({products: products});
  }

  renderProducts() {
    return (this.state.products.length > 0 &&
      <ul className="list-group">
        { this.state.products.map((product, id) => {
          return (
            <li className="list-group-item" key={ `product-${product.name}-${id}` }>
              <div className="row">
                <div className="col-xs-3">
                  <img src={ product.image_preview } className="img-thumbnail" 
                  style={{width: '30px', marginLeft: 'auto', marginRight: 'auto'}} />
                </div>
                <div className="col-xs-4">
                  <p>{ product.name }</p>
                </div>
                <div className="col-xs-3">
                  <p>{ this.moneyFormat(product.price) }</p>
                </div>
                <div className="col-xs-2" style={{textAlign: 'center'}}>
                  <button onClick={(e) => { this.handleRemove(id) }} className="btn btn-sm btn-default">X</button>
                </div>
              </div>
            </li>
          )
        }) }
      </ul>)
  }

  renderProductsTable() {
    return (this.state.products.length > 0 &&
      <table className="table table-bordered" style={{ fontSize: '12px' }}>
        <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Name</th>
          <th>Price</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        { this.state.products.map((product, id) => {
          return (
            <tr key={ `product-${product.name}-${id}` }>
              <td className="text-center">
                <img src={ product.image_preview } className="img-thumbnail" 
                  style={{width: '50px', marginLeft: 'auto', marginRight: 'auto'}} />
              </td>
              <td>
                <p style={{fontSize: '12px', marginTop: '10px'}}>{ product.name }</p>
              </td>
              <td>
                <p style={{fontSize: '12px', marginTop: '10px'}}>{ this.moneyFormat(product.price) }</p>
              </td>
              <td>
                <button onClick={(e) => { this.handleRemove(id) }} className="btn btn-sm btn-default">X</button>
              </td>
            </tr>
          ) 
          })
        }
        </tbody>
      </table>)
  }

  handleOpenTnC() {
    console.log('handleOpenTnC');
    this.setState({tnc_modal_open: true});
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { val_name } = this.state;
    if(val_name != "") {
      await this.validateForm();
      if(this.state.errors.size > 0) {
        return;
      }
      await this.pushToProducts();
    }

    await this.validateForm(true);

    if(this.state.errors.size > 0) {
      ToastsStore.error("Please fill all required fields.");
      return;
    }

    if(!this.state.tnc_agree) {
      this.handleOpenTnC();
      return;
    }

    const { customer } = this.props;
    const { products, val_firstname, val_lastname, val_phone, val_whatsapp, val_line, val_address,
            val_zipcode, val_state_id, val_pickup_date, val_pickup_time, 
            val_bank, val_account_name, val_account_number, val_referral_code } = this.state;
    
    let payload = new FormData();
    
    payload.append('customer_id', customer.id)
    products.forEach((product, i) => {
      payload.append(`products[][category]`, product.category);
      payload.append(`products[][brand]`, product.brand);
      payload.append(`products[][name]`, product.name);
      payload.append(`products[][condition]`, product.condition);
      product.includes.forEach((include) => {
        payload.append(`products[][includes][]`, include);
      })
      payload.append(`products[][description]`, product.description);
      payload.append(`products[][price]`, product.price);
      payload.append(`products[][image]`, product.image, product.image.name);
    });
    payload.append('firstname', val_firstname)
    payload.append('lastname', val_lastname)
    payload.append('phone', val_phone)
    payload.append('whatsapp', val_whatsapp)
    payload.append('line', val_line)
    payload.append('address', val_address)
    payload.append('zipcode', val_zipcode)
    payload.append('state_id', val_state_id.value)
    payload.append('pickup_date', val_pickup_date)
    payload.append('pickup_time', val_pickup_time)
    payload.append('bank', val_bank)
    payload.append('account_name', val_account_name)
    payload.append('account_number', val_account_number)
    payload.append('referral_code', val_referral_code)

    let addConsignmentRequest = {
      url: `/consignments_new.json`,
      data: payload,
      config: {
        headers: {'X-CSRF-Token': this.props.csrf, 'content-type': 'multipart/form-data'},
      },
    };
    console.log('addConsignmentRequest = ', addConsignmentRequest);
    this.setState({is_loading: true});
    axios
      .post(
        addConsignmentRequest.url,
        addConsignmentRequest.data,
        addConsignmentRequest.config,
      )
      .then(response => {
        console.log('handleSubmit response:', response);
        this.setState({is_loading: false});
        ToastsStore.info("Thank You! your consignment products submitted successfully.");
        window.setTimeout(() => {
          window.location.href = this.props.next_path;
        }, 2000) // 2000 means 2 seconds
      })
      .catch(error => {
        this.setState({is_loading: false});
        ToastsStore.error(`Whoops, your consignment products cannot be submitted.`);
        console.log('handleSubmit error:', error, error.response);
      });
  }

  fieldFeedback(state_name) {
    const { errors } = this.state;
    if(errors.has(state_name)) {
      return (<div className="invalid-feedback">{ errors.get(state_name) }</div>);
    } 
  }

  render () {
    const { customer, address, bank_account, accessories } = this.props;
    const { errors } = this.state;
    return (
      <form className="form-horizontal form-list-item" id="new_consignment_form">
        <div className="container"> {/* .container - 1 */}
          <div className="row">
            <h1 className="title">List Your Items</h1>
          </div>
          <div className="row visible-xs">
            <div className="col-md-12">
              { this.renderProducts() }
            </div>
          </div>
          <div className="row"> {/* .row - 1 */}
            <div className="col-md-8">
              <div className="form-group">
                <label className="control-label col-sm-3" htmlFor="category">Product</label>
                <div className="col-sm-9">
                  <Select
                    value={this.state.val_category}
                    onChange={this.handleChangeCategory}
                    options={this.loadCategories()}
                    className="react-select"
                  />
                  { this.fieldFeedback('val_category') }
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-3" htmlFor="category">Brand</label>
                <div className="col-sm-9">
                  <Select
                    value={this.state.val_brand}
                    onChange={this.handleChangeBrand}
                    options={this.loadBrands()}
                    className="react-select"
                  />
                  { this.fieldFeedback('val_brand') }
                  <p style={{ fontSize: '12px', marginTop: '2px'}}>Please note that we currently only accept items from this list of brands</p>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-3" htmlFor="category">Name</label>
                <div className="col-sm-9">
                  <input type="text" placeholder="Write your product name..." 
                    className="form-control" 
                    onChange={(event) => { this.setState({val_name: event.target.value}) }} 
                    value={ this.state.val_name }/>
                  { this.fieldFeedback('val_name') }
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-3 col-xs-12" htmlFor="condition">Condition</label>
                <div className="col-sm-9">
                  <ToggleButtonGroup
                    value={this.state.val_condition}
                    exclusive
                    size="small"
                    onChange={(selected, value) => { this.setState({ 'val_condition': value}) }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="new" aria-label="new">
                      New
                    </ToggleButton>
                    <ToggleButton value="used" aria-label="used">
                      Used
                    </ToggleButton>
                  </ToggleButtonGroup>
                  { this.fieldFeedback('val_condition') }
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-3 col-xs-12" htmlFor="condition">Includes</label>
                <div className="col-sm-9">
                  { this.renderAccessories() }
                </div>
              </div>
            </div> {/* .col-md-12 */}
            <div className="col-md-4 hidden-xs">
              { this.renderProductsTable() }
            </div>
          </div> {/* .row -1 */}
          <div className="row"> {/* .row - 2 */}
            <div className="col-md-12">

              <div className="form-group">
                <label className="control-label col-sm-2 col-xs-12" htmlFor="description">Description</label>
                
                <div className="col-sm-6">
                  <textarea placeholder="Put your additional notes..." 
                    className="form-control text-area col-sm-11" 
                    value={ this.state.val_description }
                    onChange={(event) => { this.setState({ 'val_description': event.target.value }) } }
                  />
                </div>

                <div className="col-sm-4 keterangan">
                  <p>Include any details that will help establish a value for your item.</p>
                  <p>Examples: Condition details, original retail price, retailer where originally purchased, etc.</p>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="photo">Photo</label>
                
                <div className="col-sm-6 col-xs-6">
                  <Button variant="outlined" size="large" color="default" onClick={(e) => { this.showOpenFileDlg(e) }}>
                    <PhotoCamera />
                  </Button>
                  <input ref={this.inputOpenFileRef} type="file" style={{display:"none"}} onChange={this.fileOnChangeHandler} accept="image/*"/>
                  {
                    this.state.val_preview && <img src={ this.state.val_preview } className="img-thumbnail" style={{marginTop: '20px'}} />
                  }
                  { this.fieldFeedback('val_image') }
                </div>
                <div className="col-sm-4 col-xs-6  keterangan">
                  <p id="fileHelp" className="form-text">Select image for your product. <br/>
                    * .png, .jpg, or .jpeg<br/>
                    * max 2MB</p>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="condition">Selling Price</label>
                
                <div className="col-sm-6" >
                  {/* <input type="text" placeholder="Pricing..." className="form-control"/> */}
                  <CurrencyInput className="form-control" style={{marginBottom: '15px'}} placeholder="Pricing..."
                    prefix="IDR " precision="0" allowEmpty={true}
                    value={this.state.val_price}
                    onChangeEvent={this.handleChangePrice}  
                  />
                  { this.fieldFeedback('val_price') }
                </div>
                <div className="col-sm-4">
                  { this.renderYouGet() }
                </div>
              </div>

              <div className="row" style={{ marginBottom: "6rem" }}>
                <div className="col-sm-2 col-sm-offset-5 col-xs-6 col-xs-offset-3">
                  <button type="button" className="btn btn-outline btn-block" onClick={this.handleAddMore}>Add More Items</button>
                </div>
              </div>
              
            </div> {/* .col-md-12 */}
          </div> {/* .row - 2 */}

        </div> {/* .container - 1 */}

        {/* Pickup Details */}
        <div className="row pickup-detail">
          <div className="container">
            <h3 className="title text-center">Pickup Details</h3>
            <div className="col-sm-10 col-sm-offset-1">

              <div className="form-pickedup">
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="first_name">First Name</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="First name..." className="form-control" 
                      value={ this.state.val_firstname }
                      onChange={(event) => { this.setState({val_firstname: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_firstname') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="last_name">Last Name</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Last name..." className="form-control" 
                      value={ this.state.val_lastname }
                      onChange={(event) => { this.setState({val_lastname: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_lastname') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="phone">Contact No</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Contact number..." className="form-control" 
                      value={ this.state.val_phone }
                      onChange={(event) => { this.setState({val_phone: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_phone') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="whatsapp">Whatsapp</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Whatsapp number..." className="form-control" 
                      value={ this.state.val_whatsapp }
                      onChange={(event) => { this.setState({val_whatsapp: event.target.value}) }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="line">Line</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Line id..." className="form-control" 
                      value={ this.state.val_line }
                      onChange={(event) => { this.setState({val_line: event.target.value}) }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="address">Address</label>
                  <div className="col-sm-6">
                    <textarea placeholder="Address" className="form-control text-area col-sm-11" rows="2" 
                      value={ this.state.val_address }
                      onChange={(event) => { this.setState({val_address: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_address') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="first_name">Postal Code</label>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Postal code..." className="form-control" 
                      value={ this.state.val_zipcode }
                      onChange={(event) => { this.setState({val_zipcode: event.target.value}) }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="state">Province</label>
                  <div className="col-sm-6">
                    <Select
                      value={ this.state.val_state_id}
                      onChange={this.handleChangeStateId}
                      options={this.loadProvinces()}
                      className="react-select"
                    />
                    { this.fieldFeedback('val_state_id') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="date-pickup">Prefered Pick Up Date</label>
                  <div className="col-sm-6">
                    <input type="date" placeholder="Pickup date..." className="form-control" 
                      value={ this.state.val_pickup_date }
                      onChange={(event) => { this.setState({val_pickup_date: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_pickup_date') }
                  </div>
                </div>
                <div className="form-group pu-time">
                  <label className="control-label col-sm-3" htmlFor="pu_time">Prefered Pick Up Time</label>
                  <div className="col-sm-6">
                    <ToggleButtonGroup
                      value={ this.state.val_pickup_time }
                      exclusive
                      size="small"
                      onChange={(selected, value) => { this.setState({ 'val_pickup_time': value}) }}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="09:00 - 12:00" aria-label="09:00 - 12:00">
                        09:00 - 12:00
                      </ToggleButton>
                      <ToggleButton value="13:00 - 16:00" aria-label="13:00 - 16:00">
                        13:00 - 16:00
                      </ToggleButton>
                    </ToggleButtonGroup>
                    { this.fieldFeedback('val_pickup_time') }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* My Payment Details */}
        <div className="container">
          <div className="row payment-detail">
          
            <h3 className="title text-center">My Payment Details</h3>
            <div className="col-sm-6">
              <div className="form-payment">
                <div className="form-group payment-type">
                  <label className="control-label col-sm-4 col-xs-12" htmlFor="payment_type">Bank</label>
                  <div className="col-sm-8">
                    <ToggleButtonGroup
                      value={this.state.val_bank}
                      exclusive
                      size="small"
                      onChange={(selected, value) => { this.setState({'val_bank': value}) }}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="bca" aria-label="BCA">
                        <img src={this.props.guide_images.bca2} style={{width: '80px'}} />
                      </ToggleButton>
                      <ToggleButton value="mandiri" aria-label="MANDIRI">
                        <img src={this.props.guide_images.mandiri2} style={{width: '80px'}} />
                      </ToggleButton>
                    </ToggleButtonGroup>
                    { this.fieldFeedback('val_bank') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="account_number">No Rekening</label>
                  <div className="col-sm-8 text-center">
                    <input type="text" placeholder="Account number..." className="form-control" 
                      value={ this.state.val_account_number }
                      onChange={(event) => { this.setState({val_account_number: event.target.value}) }}
                    />
                    { this.fieldFeedback('val_account_number') }
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="first_name">Name</label>
                  <div className="col-sm-8">
                    <input type="text" placeholder="Account name..." className="form-control"
                      value={ this.state.val_account_name }
                      onChange={(event) => { this.setState({val_account_name: event.target.value}) }} 
                    />
                    { this.fieldFeedback('val_account_name') }
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginBottom: "5rem" }}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="referral_code">Kode Referral</label>
                  <div className="col-sm-8 text-center">
                    <input type="text" placeholder="Write your referral code..." className="form-control"
                      value={ this.state.val_referral_code }
                      onChange={(event) => { this.setState({val_referral_code: event.target.value}) }} 
                    />
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "6rem" }}>
          <div className="col-sm-2 col-sm-offset-5 col-xs-6 col-xs-offset-3">
            <button type="button" ref={this.submitBtnRef} className="btn btn-outline btn-block" id="consign_submit_btn" disabled={this.state.is_loading} onClick={ this.handleSubmit }>{ this.state.is_loading ? 'Loading ...' : 'Submit'}</button>
          </div>
        </div>


        <ConsignmentTnc show={this.state.tnc_modal_open} 
          onHide={() => this.setState({tnc_modal_open: false, tnc_agree: false})} 
          onNext={() => this.setState({tnc_modal_open: false, tnc_agree: true}, () => { this.submitBtnRef.current.click();})} 
          guide_images={this.props.guide_images}
        />
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} lightBackground/>
      </form>
      
    );
  }
}

export default ConsignmentForm
